import { Route, Routes } from 'react-router';

import { NoMatch } from 'components';
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';
import { AppLayout } from 'layouts/AppLayout';
import { AuthLayout } from 'layouts/AuthLayout';
import { Auth } from 'features/auth';
import { Dashboard } from 'features/dashboard';
import { Billing } from 'features/billing';
import { Feedbacks } from 'features/feedbacks';
import { Inventory } from 'features/inventory';
import { Platforms } from 'features/platforms';
import { Settings } from 'features/settings';
import { Support } from 'features/support';
import { Templates } from 'features/templates';
import { Users } from 'features/users';
import { POS } from 'features/pos';
import { Shifts } from 'features/shifts';
import { HelpDesk } from 'features/helpdesk';

const Router = () => (
  <Routes>
    <Route
      path="/auth"
      element={
        <AuthRoute>
          <AuthLayout />
        </AuthRoute>
      }
    >
      <Route index element={<Auth />} />
      <Route path="sessions" element={<Auth />} />
    </Route>

    <Route
      element={
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      }
    >
      <Route index element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="billing/*" element={<Billing />} />
      <Route path="messages/*" element={<Feedbacks />} />
      <Route path="inventory/*" element={<Inventory />} />
      <Route path="api-keys/*" element={<Platforms />} />
      <Route path="pos/*" element={<POS />} />
      <Route path="shifts/*" element={<Shifts />} />
      <Route path="faq/*" element={<Support />} />
      <Route path="templates/*" element={<Templates />} />
      <Route path="users/*" element={<Users />} />
      <Route path="settings" element={<Settings />} />
      <Route path="helpdesk" element={<HelpDesk />} />
    </Route>
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export default Router;
