import { ReactNode } from 'react';
import { Icon } from '@smart-kasa/ui';

import { TransactionType } from 'services/api/core/receipts/enums';
import styles from './ReceiptPaymentMethod.module.scss';

const PAYMENT_METHOD_ICONS: Record<TransactionType, { name: string; icon: ReactNode }> = {
  [TransactionType.cash]: {
    name: 'Готівка',
    icon: <Icon name="cash" color="accent" />,
  },
  [TransactionType.card]: {
    name: 'Картка',
    icon: <Icon name="terminal" color="secondary" />,
  },
  [TransactionType.acquiring]: {
    name: 'Еквайринг',
    icon: <Icon name="receipt" color="primary" />,
  },
  [TransactionType.accountTransfer]: {
    name: 'За реквізитами (IBAN)',
    icon: <Icon name="bank2" color="primary" />,
  },
  [TransactionType.cardTransfer]: {
    name: 'Переказ з картки',
    icon: <Icon name="creditCard" color="secondary" />,
  },
};

interface IReceiptPaymentMethod {
  type: TransactionType;
  name?: ReactNode;
  children?: never;
}

export const ReceiptPaymentMethod = ({ type, name }: IReceiptPaymentMethod) => (
  <div className={styles.paymentMethod}>
    <div className={styles.info}>
      {name || <span className={styles.name}>{PAYMENT_METHOD_ICONS[type].name}</span>}
    </div>

    {PAYMENT_METHOD_ICONS[type].icon}
  </div>
);
