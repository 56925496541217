import { useIntl } from 'react-intl';
import { ROUTES } from 'routes';
import { Icon, Menu, IMenuProps } from '@smart-kasa/ui';

import { MenuLink } from './MenuLink';
import { MenuGroup } from './MenuGroup';

export type NavigationProps = Omit<IMenuProps, 'children'> & {
  children?: never;
};

export const Navigation = (props: NavigationProps) => {
  const { formatMessage } = useIntl();

  return (
    <Menu {...props}>
      <MenuLink
        title={formatMessage({ id: 'navigation.dashboard' })}
        to={ROUTES.dashboard()}
        icon={<Icon name="grid" />}
      />
      <MenuLink
        title={formatMessage({ id: 'navigation.users' })}
        to={ROUTES.users()}
        icon={<Icon name="user" />}
      />
      <MenuGroup
        title={formatMessage({ id: 'navigation.billing' })}
        namespace={ROUTES.billing()}
        icon={<Icon name="creditCard" />}
      >
        <MenuLink
          title={formatMessage({ id: 'navigation.billingPlans' })}
          to={ROUTES.billingPlans()}
          nested
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.billingSubscriptions' })}
          to={ROUTES.billingSubscriptions()}
          nested
        />
      </MenuGroup>
      <MenuGroup
        title={formatMessage({ id: 'navigation.pos' })}
        namespace={ROUTES.pos()}
        icon={<Icon name="smartKasa" />}
      >
        <MenuLink
          title={formatMessage({ id: 'navigation.posCoreTerminals' })}
          to={ROUTES.posCoreTerminals()}
          nested
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.posTerminals' })}
          to={ROUTES.posTerminals()}
          nested
        />
      </MenuGroup>
      <MenuGroup
        title={formatMessage({ id: 'navigation.inventory' })}
        namespace={ROUTES.inventory()}
        icon={<Icon name="shopApps" />}
      >
        <MenuLink
          title={formatMessage({ id: 'navigation.inventoryUnits' })}
          to={ROUTES.inventoryUnits()}
          nested
        />
      </MenuGroup>
      <MenuGroup
        title={formatMessage({ id: 'navigation.faq' })}
        namespace={ROUTES.faq()}
        icon={<Icon name="folder" />}
      >
        <MenuLink
          title={formatMessage({ id: 'navigation.faqCategories' })}
          to={ROUTES.faqCategories()}
          nested
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.faqQuestions' })}
          to={ROUTES.faqQuestions()}
          nested
        />
      </MenuGroup>
      <MenuLink
        title={formatMessage({ id: 'navigation.feedbackMessages' })}
        to={ROUTES.feedbackMessages()}
        icon={<Icon name="mail" />}
      />
      <MenuLink
        title={formatMessage({ id: 'navigation.helpdesk' })}
        to={ROUTES.helpDesk()}
        icon={<Icon name="support" />}
        badge="BETA"
      />
      <MenuLink
        title={formatMessage({ id: 'navigation.templates' })}
        to={ROUTES.templates()}
        icon={<Icon name="template" />}
      />
      <MenuLink
        title={formatMessage({ id: 'navigation.platforms' })}
        to={ROUTES.platforms()}
        icon={<Icon name="security" />}
      />
      <MenuLink
        title={formatMessage({ id: 'navigation.settings' })}
        to={ROUTES.settings()}
        icon={<Icon name="settings" />}
      />
    </Menu>
  );
};
